<template>
  <component :is="component" v-bind="componentProps" @click="onClick">
    <slot :is-external="isExternal" />
  </component>
</template>

<script lang="ts" setup>
const EXTERNAL_URL_EXTENSIONS = ['jpg', 'png', 'svg', 'pdf']

function getUrlExtension(url: string): string {
  try {
    const parts = url.split(/[#?]/)
    return parts[0].split('.').pop()?.trim() || ''
  } catch (_e) {
    return ''
  }
}

const props = defineProps<{
  to: string
  target?: string
  isLink?: boolean
  activeClass?: string
}>()

const router = useRouter()
const route = useRoute()

const href = computed(() => {
  if (!props.to) {
    return ''
  }

  if (props.to.startsWith('www')) {
    return 'https://' + props.to
  }

  return props.to
})

const component = computed(() => {
  return href.value ? 'a' : 'div'
})
const urlExtension = computed(() => {
  return getUrlExtension(href.value).toLowerCase()
})

const isExternal = computed(
  () =>
    href.value.startsWith('http') ||
    EXTERNAL_URL_EXTENSIONS.includes(urlExtension.value),
)

const componentProps = computed<Record<string, string | string[]>>(() => {
  const componentProps: { class: string[]; href?: string; target?: string } = {
    class: [],
  }
  if (href.value) {
    componentProps.href = href.value

    if (isExternal.value) {
      componentProps.target = '_blank'
    }
  }

  if (props.isLink) {
    componentProps.class.push('link')
    componentProps.class.push(isExternal.value ? 'is-external' : 'is-internal')
  }

  if (isActive.value) {
    componentProps.class.push(props.activeClass || 'nuxt-link-exact-active')
  }

  return componentProps
})
const isActive = computed(() => {
  return route.path && href.value ? route.path.startsWith(href.value) : false
})
function onClick(e: Event) {
  if (isExternal.value) {
    return
  }

  e.preventDefault()
  router.push(href.value)
}
</script>
